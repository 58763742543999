<template>
    <div >
        <div>
            <slot/>
        </div>
    </div>
</template>

<script setup lang="ts">
useHead({
    htmlAttrs: {
        // class: 'dark'
    }
});
</script>
